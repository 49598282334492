export const ENDPOINT = process.env.REACT_APP_BACKEND_HOST
export const ENDPOINT_BI = process.env.REACT_APP_BI_HOST
const ENDPOINT_WS = process.env.REACT_APP_WORKSCHEDULE_HOST

export const fetchDataGet = async (url, params = "") => {
    let data = null
    try {
        const config = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        }
        const response = await fetch(`${ENDPOINT_BI}${url}?${params}`, config)
        responseVerify(response)
        data = await response.json()
    } catch (e) {
        return;
    }

    return data
}

export const fetchWorkScheduleData = async (patch) => {
    let data = null
    try {
        const config = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        }
        const response = await fetch(`${ENDPOINT_WS}${patch}`, config)
        responseVerify(response)
        data = await response.json()
    } catch (e) {
        return;
    }

    return data
}

export const fetchData = async (url, params = "") => {
    let data = null
    try {
        const tokenString = localStorage.getItem('token');    
        const token = JSON.parse(tokenString)
        const config = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}
        }
        const response = await fetch(`${ENDPOINT}${url}?${params}`, config)
        responseVerify(response)
        data = await response.json()
    } catch (e) {
        return;
    }

    return data
}

const responseVerify = (response) => {
    if (response.ok) return;
    if(response.status === 401) window.location.href = '/logout';
}