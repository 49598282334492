import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { fetchData } from '../../api/Constants';
import QuestionItem from './QuestionItem';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let scores = []

const Questions = (props) => {
    const [open, setOpen] = React.useState(false);
    const [btnDisabled, setBtnDisabled] = React.useState(true);
    const { 
        evaluationId, 
        groupId, 
        userId,
        user, 
        questions, 
        removeUser,
        setOpenBackdrop 
    } = props
    
    
    const handleClickOpen = () => {
        scores = []
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSend = async () => {
        setOpen(false);
        setOpenBackdrop(true)
        let params = []
        scores.forEach(item => {
            let strParams = ""
            const keys = Object.keys(item)
            const values = Object.values(item)

            for (let index = 0; index < keys.length; index++) {
                strParams +=  `${keys[index]}=${values[index]}&`
            }

            params.push(strParams)
        });

        await Promise.all(params.map(async (itemParams) => {
            await fetchData(`/api/v2/users/${userId}/scores`, itemParams)
        }));
        removeUser(userId)
        setOpenBackdrop(false)
    }

    const readyToSendVerify = () => {
        let isReady = true
        scores.forEach(item => {
            if (item.value === null) isReady = false
        });

        if (isReady && (scores.length === questions.length)){
            setBtnDisabled(false)
        } else {
            setBtnDisabled(true)
        }     
    }

    const setScore = (questionId, newValue) => {
        const index = scores.findIndex((score => score.question_id === questionId));
        if (index >= 0) {
            scores[index].value = newValue 
        } else {
            scores.push({
                evaluation_id: evaluationId,
                group_id: groupId,
                user_id: userId,
                owner_id: localStorage.getItem('uid'),
                question_id: questionId, 
                value: newValue
            })
        }
        readyToSendVerify()
    };

    return (
        <div>
            <Button 
                variant="outlined" 
                size="small"
                color="success"
                onClick={handleClickOpen}>
                Avaliar
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{user}</DialogTitle>
                <DialogContent>
                    {questions.map((question, index) => {
                        return(
                        <QuestionItem key={index} question={question} setScore={setScore} isOpen={open} />
                    )})} 
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button
                        disabled={btnDisabled} 
                        onClick={handleSend}>
                        Concluir avaliação
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Questions;