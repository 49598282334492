import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {ListItemText} from '@mui/material';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography, Breadcrumbs, Link, Skeleton } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import Alert from '@mui/material/Alert';
import UserScore from './UserScore';
import Questions from './Questions';
import { useParams } from "react-router-dom"
import { fetchData } from '../../api/Constants'

function Users() {
    const [item, setItem] = useState(null);
    const [users, setUsers] = useState([]);     
    const { evaluationId, groupId } = useParams();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    
    useEffect(() => { 
        const fetchUsers = async () => {
            const uid = localStorage.getItem('uid')
            const data = await fetchData(`/api/v2/users/${uid}/evaluations/${evaluationId}/groups/${groupId}/users`)
            setUsers(data.evaluation.users)
            setItem(data);
            if (data.evaluation.users.length === 0) setShowAlert(true)
        }
        fetchUsers()
    }, [evaluationId, groupId]);

    const removeUser = (id) => {
        setUsers(users.filter(user => user.id !== id))
    }

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb"  sx={{ mb: 2}}>
                <Link underline="hover" color="inherit" href="/" sx={{ display: 'flex', alignItem: 'center' }}>
                    <HomeIcon sx={{ mr: 0.5 }} />
                    Página inicial
                </Link>
                <Link underline="hover" color="inherit" href="/evaluations" sx={{ display: 'flex', alignItems: 'center' }}>
                    Avaliação 360
                </Link>
                <Link underline="hover" color="inherit" href={`/evaluations/${evaluationId}/groups`} sx={{ display: 'flex', alignItems: 'center' }}>
                    Grupos
                </Link>
                <Typography color="text.primary">Usuários</Typography>
            </Breadcrumbs>

            <Card sx={{ 
                minHeight: 400, 
                marginBottom: 40,
                backgroundImage: `url(${"/logo.png"})`,
                backgroundSize: '20%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center' }}>
                <CardContent sx={{ bgcolor: 'background.paper', opacity: 0.92,  minHeight: 400 }}>
                    <UserScore  />
                    <Typography variant="h6" component="h2">
                        {item ? item.evaluation.name : <Skeleton variant="text" />}
                    </Typography>
                    <Typography variant="subtitle1" component="h2">
                        {item ? item.evaluation.description : <Skeleton variant="text" />}
                    </Typography>

                    { showAlert ? <Alert severity="info" sx={{ mb: 5}}>Todos os usuários foram avaliados.</Alert> : null }

                    <List dense={true}>
                        {item ? users.map((user, index) => {
                            return(
                                <div key={index}>
                                    <ListItem 
                                            selected={true} 
                                            divider={true}
                                            sx={{ mb: 1}}
                                            secondaryAction={
                                                <Questions
                                                    evaluationId={evaluationId}  
                                                    groupId={groupId}
                                                    userId={user.id}
                                                    user={`${item.evaluation.group.name} - ${user.name}`}
                                                    questions={item.evaluation.questions}
                                                    removeUser={removeUser}
                                                    setOpenBackdrop={setOpenBackdrop}
                                                />
                                            }
                                        >
                                        <ListItemAvatar>
                                            <Avatar alt={user.name} src={user.avatar} sx={{ bgcolor: '#faaf00'}} />
                                        </ListItemAvatar>
                                        
                                        <ListItemText
                                            primary={user.name}
                                            secondary={item.evaluation.group.name}
                                        />
                                    </ListItem>
                                </div>
                            )       
                        }) : null } 
                    </List>
                </CardContent>
            </Card>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
  );
}

export default Users;