import React, {useState} from 'react';
import axios from 'axios';

import { LOGIN_URL, USERS_URL, CONFIG } from './Constants';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import {Box, Stack, Alert} from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const styled = {
  pageContainer: {
    position: 'relative',
    minHeight: '100vh'
  },
  contentwrap: {  
    paddingBottom: '3.0rem' //altura do rodapé
  },
  footer: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    height: '3.0rem' //altura do rodapé
  }
  
};

function Copyright(props) {
  return (
    <Box sx={styled.footer}>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="#">
          DTI
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  );
}

const theme = createTheme();

const ForgotPassword = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [cpf, setCpf] = useState("");

  const loginAdmin = () => {
    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_KEYCLOAK_ADMIN_CLIENT_ID);
    params.append('grant_type', process.env.REACT_APP_KEYCLOAK_GRANT_TYPE);
    params.append('username', process.env.REACT_APP_KEYCLOAK_ADMIN_USERNAME);
    params.append('password', process.env.REACT_APP_KEYCLOAK_ADMIN_PASSWORD);

    return axios.post(LOGIN_URL, params, CONFIG);
  }

  const cpfMask = (cpf) => {
    if (cpf.length <= 14) {
      cpf=cpf.replace(/\D/g,"");                    //Remove não dígitos
      cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2");       //Coloca um ponto entre o terceiro e o quarto dígito
      cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2");       //Coloca um ponto entre o sexto e o setimo dígito
      cpf=cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2"); //Coloca um hífen entre o nono e o decimo dígito
      setCpf(cpf);
    }
  }

  const cpfValidLenght = () => {
    return Boolean(cpf.length === 14);
  }

  const getUser = (token) => {
    const url = `${USERS_URL}?username=${cpf.replace(/\D/g,"")}`;
    const config = {
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    }
    
    return axios.get(url, config);
  }

  const executeActionsEmail = (userId, token) => {
    const url = `${USERS_URL}/${userId}/execute-actions-email`;
    const params = ["UPDATE_PASSWORD"];
    const config = {
      headers: { 
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': `Bearer ${token}`
      }
    }
    
    return axios.put(url, params, config);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!cpfValidLenght()) return;
    
    try {
      setOpenBackdrop(true);
      const loginAdminResponse = await loginAdmin();
      const getUserResponse = await getUser(loginAdminResponse.data.access_token);
      await executeActionsEmail(getUserResponse.data[0].id, loginAdminResponse.data.access_token);
      setOpenBackdrop(false);
      setResetSuccess(true);
    } catch (error) {
      setOpenBackdrop(false);
      setOpenSnackbar(true);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={styled.pageContainer}>
        <Box sx={styled.contentwrap}>
          <Container component="main" maxWidth="xs" sx={{pt: 10}}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}>
              <Avatar 
                sx={{ width: 128, height: 128 }} 
                alt="DTI" 
                src="/logo_378x361.png" />
                Corregedoria Geral da SDS
              <Typography component="h1" variant="h5">
                Intranet
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                Esqueci minha senha
              </Typography>
              {resetSuccess ? <Alert severity="success">Sucesso! Acesse seu email cadastrado e siga as instruções para resetar sua senha.</Alert> : null}
            </Stack>

            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1}}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="cpf"
                label="CPF"
                name="cpf"
                autoComplete="cpf"
                value={cpf}
                onChange={(event) => {
                  cpfMask(event.target.value);
                }}
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!cpfValidLenght()}
              >
                Solicitar instruções para alteração <br /> 
                de senha por email
              </Button>
              <Link 
                href={`/`} 
                underline="hover" 
                rel="noopener" 
                sx={{ mt: 4, mb: 2 }}>
                Voltar
              </Link>
            </Box>
          </Container>
        </Box>
        <Copyright />

        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Erro ao resetar senha! Verifique o CPF digitado ou entre em contato com o DTI."
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose} >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </ThemeProvider>
  );
}

export default ForgotPassword;