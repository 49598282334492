import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import { AuthContext } from "./AuthContext";
import { Navigate } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {Grid, Stack} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';


import { LOGIN_URL, CONFIG } from './Constants';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        DTI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {
  const { login, isLoggedIn } = useContext(AuthContext);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState({url: "", copyright: ""});
  const [cpf, setCpf] = useState("");


  useEffect(() => { 
    const data = require('./images.json');
    const max = data.length;
    const index = Math.floor(Math.random() * max);
    setBackgroundImage(data[index]);
  }, []);
    
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (cpf.length === 14) {
      const fields = new FormData(event.currentTarget);
      setOpenBackdrop(true);

      const params = new URLSearchParams();
      params.append('client_id', process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
      params.append('grant_type', process.env.REACT_APP_KEYCLOAK_GRANT_TYPE);
      params.append('client_secret', process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET);
      params.append('scope', process.env.REACT_APP_KEYCLOAK_SCOPE);
      params.append('username', cpf.replace(/\D/g,""));
      params.append('password', fields.get('password'));
      
      axios.post(LOGIN_URL, params, CONFIG).then((response) => {
        login(response.data.access_token, response.data.refresh_token);
      }).catch(error => {
        setOpenSnackbar(true);
      });

      setOpenBackdrop(false);
    }
  };

  if (isLoggedIn()) {
    return <Navigate to="/" />;
  }

  const cpfMask = (cpf) => {
    if (cpf.length <= 14) {
      cpf=cpf.replace(/\D/g,"");                    //Remove não dígitos
      cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2");       //Coloca um ponto entre o terceiro e o quarto dígito
      cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2");       //Coloca um ponto entre o sexto e o setimo dígito
      cpf=cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2"); //Coloca um hífen entre o nono e o decimo dígito
      setCpf(cpf);
    }
  }


  const formLogin = () => {
    return(
      <div>
        <Stack
          sx={{ mt: 5}}
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}>
          <Avatar 
            sx={{ m: 1, width: 128, height: 128 }} 
            alt="DTI" 
            src="/logo_378x361.png" />
            Corregedoria Geral da SDS
          <Typography component="h1" variant="h5">
            Intranet
          </Typography>
        </Stack>

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 5 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="cpf"
            label="CPF"
            name="cpf"
            autoComplete="cpf"
            autoFocus
            value={cpf}
            onChange={(event) => {
              cpfMask(event.target.value);
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password" />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}>
            Entrar
          </Button>
          <Link 
            href={"/forgot"} 
            underline="hover"
            rel="noopener" 
            sx={{ mt: 4, mb: 2 }}>
            Esqueci minha senha
          </Link>
        </Box>
      </div>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        backgroundImage: `url(${backgroundImage.url})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: '100vh',
      }}>
     
        <Box sx={{ flexGrow: 1, margin: 0 }}>
          <Grid container spacing={0}>
            <Grid item xs={8} />
            <Grid item xs={4}>
            <Box sx={{
                background: "rgb(255, 255, 255, 0.92)",
                height: '100vh',
                padding: '2rem',
                borderWidth: '5px',
                borderStyle: 'solid',
                borderImage: 'linear-gradient(to bottom, #1976d2, rgba(0, 0, 0, 0)) 1 100%'}}>
                <CssBaseline />
                {formLogin()}
                <Copyright sx={{ mt: 8, mb: 4 }} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Erro ao logar no sistema! Verifique suas credenciais."
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose} >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </ThemeProvider>
  );
}