import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Card, CardActions, CardContent } from '@mui/material';
import { Button, Skeleton } from '@mui/material';
import { fetchData } from '../../api/Constants';

const LastNews = () => {
  const [news, setNews] = useState(null);
  
  useEffect(() => {
    const fetchNews = async () => {
      const data = await fetchData('/api/v2/news', 'limit=6')
      setNews(data.news);
    }
    fetchNews() 
  }, []);

  return (
    <Card
      sx={{
        minWidth: 275,
        backgroundImage: `url(${'./logo.png'})`,
        backgroundSize: '55%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <CardContent>
        <Typography gutterBottom variant="h5" component="span">
          Últimas notícias
        </Typography>
        <List sx={{ width: '100%', bgcolor: 'background.paper', opacity: 0.9 }}>
          {news ? (
            news.map((news, index) => {
              return (
                <ListItem alignItems="flex-start" key={index} disablePadding>
                  <ListItemButton
                    component="a"
                    href={`/news/${news.id}`}
                    dense={true}
                  >
                    <ListItemText
                      primary={<b>{news.title}</b>}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          ></Typography>
                          {news.department} - {news.datetime}
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })
          ) : (
            <Skeleton variant="rectangular" height={50} />
          )}
        </List>
      </CardContent>
      <CardActions sx={{ float: 'right' }}>
        <Button size="small" color="primary" href="/news">
          Ver todas
        </Button>
      </CardActions>
    </Card>
  );
};

export default LastNews;
