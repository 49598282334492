
const REALM = process.env.REACT_APP_KEYCLOAK_REALM

export const LOGIN_URL = `https://auth.corregedoria.sds.pe.gov.br/auth/realms/${REALM}/protocol/openid-connect/token`;
export const LOGOUT_URL = `https://auth.corregedoria.sds.pe.gov.br/auth/realms/${REALM}/protocol/openid-connect/logout`;
export const USERS_URL = `https://auth.corregedoria.sds.pe.gov.br/auth/admin/realms/${REALM}/users`;

export const CONFIG = {
  headers: { 'content-type': 'application/x-www-form-urlencoded' }
}

