import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Rating from '@mui/material/Rating';
import Skeleton from '@mui/material/Skeleton';
import { fetchData } from '../../api/Constants'

const Questions = () => {
    const [scores, setScores] = useState(null);

    useEffect(() => { 
        async function fetchScore() {
          const uid = localStorage.getItem('uid')
          const data = await fetchData(`/api/v2/users/${uid}/questions/report`)
          setScores(data);
        }
        fetchScore()
    }, []);
      

    return (
        <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >   
            { scores ? scores.map((score, index) => {
                return(
                    <ListItem key={index}>
                        <ListItemText 
                            primary={score.question} 
                            secondary={
                            <span style={{ display: 'flex', alignItems: 'center'}}>
                                <Rating 
                                    name="simple-controlled"
                                    size="large" 
                                    value={parseFloat(score.score)} 
                                    precision={0.1} readOnly />
                                <span style={{ marginLeft: '10px', fontSize: '24px' }}>
                                    { score.score }
                                </span>
                            </span>
                        }/>
                    </ListItem>
                )}) : <Skeleton variant="rectangular" height={50} />}
       </List>
    );
  }
  
  export default Questions;