import React from 'react';
import { Typography, Box, Card, CardContent } from '@mui/material'
import CustomSkeleton from './CustomSkeleton'

function Content({report, snippets}) {

    const topics = (arr) => {
        return arr.filter(element => {
            return element !== null;
        });
    }

    const format_content = (content) => {
        content = content.replace('align="center"', 'width=100% overflow-x=auto')
        content = content.replace('cellpadding="1"', 'cellpadding=10')
        content = content.replace('cellpadding="0"', 'cellpadding=10')
        return content.replace('cellspacing="1"', 'cellspacing=0')
    }

    return (
        <Card variant="outlined">
            <CardContent>
                {report && report.sequence_number ? <Typography variant="h4" sx={{fontFamily: 'Monospace', fontWeight: 'bold'}}>
                            Boletim interno nº {report.sequence_number} de {report.date.unabbreviated}
                            </Typography> : <CustomSkeleton size={7} /> }
                
                {snippets.map((item, index) => {
                    return (
                        <div key={index}>
                            <Typography variant="h4" component="h2" sx={{ mt: 3, mb: 1 }}>
                                {item ? item.snippet ? item.snippet.title : null : null}
                            </Typography>
                            {item ? item.topics ? topics(item.topics).map((topic, index) => {
                                return (
                                    <div key={index} style={{ display: 'block' }}>
                                        <Typography color="gray" 
                                            variant="h5"
                                            sx={{  mb: 3, fontWeight: 'bold' }}>
                                            {topic.title}
                                        </Typography>
                                        {topic.notes.map((note, index) => {
                                            return(
                                                <div key={index} style={{ display: 'block' }}>
                                                    <Typography sx={{ fontWeight: 'bold' }}>
                                                        {note.title}
                                                    </Typography>
                                                    <Box sx={{ overflow: 'auto' }}
                                                        dangerouslySetInnerHTML={{__html: format_content(note.content) }} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            }) : 'Sem alteração' : null} 
                        </div>
                    )
                })}
            </CardContent>
        </ Card>
    )
}

export default Content;