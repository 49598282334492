import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { fetchData } from '../../api/Constants'

const UserScore = () => {
  const [score, setScore] = useState(0);
    
  useEffect(() => { 
    async function fetchScore() {
      const uid = localStorage.getItem('uid')
      const data = await fetchData(`/api/v2/users/${uid}/score`)
      setScore(parseFloat(data.score));
    }
    fetchScore()
  }, []);
  
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
            sx={{ marginRight: 2}}
          >
            <Avatar 
              alt={localStorage.getItem('name')} 
              src={localStorage.getItem('avatar')}
              sx={{ width: 64, height: 64 }} />
          </StyledBadge>
        </ListItemAvatar>
        <ListItemText
          primary={localStorage.getItem('name')}
          secondary={
            <span style={{ width: 200, display: 'flex', alignItems: 'center'}}>
              <Rating 
                name="simple-controlled"
                size="large" 
                value={score} 
                precision={0.1} readOnly />
                <span style={{ marginLeft: '10px', fontSize: '24px' }}>
                  { score > 0 ? score : ''}
                </span>
            </span>
          }
        />
      </ListItem>
    </List>
  );
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

export default UserScore;