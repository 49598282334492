import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
  ListItemButton,
  Typography,
  Breadcrumbs,
  Link,
  Skeleton,
  Chip
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { fetchData } from '../../api/Constants';
import Alert from '@mui/material/Alert';
import { useLocation } from "react-router-dom";


function Index() {
  const [posts, setPosts] = useState(null);
  const [tags, setTags] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();
  const department = query.get("department")

  useEffect(() => {  
    const params = `department=${department}`;
    fetchPosts(params)
  }, [query, department]);

  const fetchPosts = async (params) => {
    setOpenBackdrop(true)
    const url = '/api/v2/departments/posts';
    const data = await fetchData(url, params)      
    setPosts(data.posts);
    setTags(data.tags);
    if(data.length === 0) setShowAlert(true)
    setOpenBackdrop(false)
  }

  const handleTagClick = (tag) => {
    const params = `department=${department}&tag=${tag}`;
    fetchPosts(params)
  }

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          href="/"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5 }} />
          Página inicial
        </Link>
        <Link underline="hover" color="inherit" href="/departments">
          Departamentos
        </Link>
        <Typography color="text.primary">Publicações {department}</Typography>
      </Breadcrumbs>
      
      { showAlert ? <Alert severity="info" sx={{ mb: 5}}>Nenhuma publicação encontrada no {department}</Alert> : null }
      
      { posts ? <Chip  
        label="Todas"
        color="primary"
        variant="outlined"
        onClick={() => handleTagClick("")}
        />
        : null 
      }
      { 
        tags.map((tag, index) => {
          return(
              <Chip 
                key={index} 
                label={tag}
                sx={{ ml: 1 }}
                color="primary" 
                variant="outlined"
                onClick={() => handleTagClick(tag)} />
            )
        })
      }


      <List sx={{ ml: -2, width: '100%', bgcolor: 'background.paper' }}>
        {posts ? (
          posts.map((post, index) => {
            return (
              <div key={index}>
                <ListItemButton
                  component="a"
                  href={post.attachment}
                  dense={true}
                >
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="DTI" src="/logo.png" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={post.title}
                      secondary={
                        <>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          ></Typography>
                          {post ? (
                            `${post.datetime}`
                          ) : (
                            <Skeleton variant="text" />
                          )}
                          {/* <Stack direction="row" spacing={1} sx={{mt: 1}}> */}
                          <Typography 
                            key={index} 
                            component={'span'} 
                            variant={'body2'}
                            sx={{ display: 'block', marginTop: '5px' }} >
                            {post.tags.map((tag, index) => {
                              return(
                                <span 
                                  key={index} 
                                  style={{ 
                                    backgroundColor: "#5684bf", 
                                    padding: '3px', 
                                    marginRight: '12px',
                                    color: "#FFFFFF", 
                                    borderRadius: '8px' 
                                  }}>{tag}</span>
                              )})}
                            </Typography>
                          {/* </Stack> */}
                        </>
                      }
                    />
                  </ListItem>
                </ListItemButton>
                <Divider variant="inset" component="li" />
              </div>
            );
          })
        ) : (
          <Skeleton variant="rectangular" height={50} />
        )}
      </List>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Index;
