import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import DefaultRoutes from './routes/default';
import MobileRoutes from './routes/mobile';
import { CookiesProvider } from 'react-cookie';


const theme = createTheme({
  palette: {
    primary: {
      main: '#5684BF',
      contrastText: "#fff"
    },
    secondary: {
      main: '#ec9f03', //'#ffcb00ff'
      contrastText: "#fff"
    },
  },
});

function App() {
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }
  
  return (
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <Default>
            <DefaultRoutes />
        </Default>
    
        <Mobile>
            <MobileRoutes />  
        </Mobile>
      </CookiesProvider>
    </ThemeProvider>
  );
 
}

export default App;
