import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import ManageSearchTwoToneIcon from '@mui/icons-material/ManageSearchTwoTone';

import Last from './Last';
import Filter from './Filter';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <span
      role="tabpanel"
      hidden={value !== index}
      id={`bi-tabpanel-${index}`}
      aria-labelledby={`bi-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
          {children}
        </Box>
      )}
    </span>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `bi-tab-${index}`,
    'aria-controls': `bi-tabpanel-${index}`,
  };
}

export default function Index() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="BI tabs" selectionFollowsFocus>
          <Tab icon={<TodayTwoToneIcon />} iconPosition="start" label="Último publicado" {...a11yProps(0)} />
          <Tab icon={<ManageSearchTwoToneIcon />} iconPosition="start" label="Filtrar" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Last />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Filter />
      </CustomTabPanel>
    </>
  );
}