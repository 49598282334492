import { createContext } from "react";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { LOGOUT_URL } from './Constants';

const AuthContext = createContext();

function AuthProvider(props) {
  const [cookies, setCookie, removeCookie] = useCookies(['access_token', 'refresh_token']);
  
  const login = (accessToken, refreshToken) => {
    setCookie('access_token', accessToken, { path: '/' });
    setCookie('refresh_token', refreshToken, { path: '/' });
  };

  const logout = async () => {
    const config = {
      headers: { 
        'content-type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${cookies.refresh_token}`
      }
    }

    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
    params.append('client_secret', process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET);
    params.append('refresh_token', cookies.refresh_token);

    axios.post(LOGOUT_URL, params, config).then((response) => {
      removeCookie('access_token');
      removeCookie('refresh_token');
    }).catch(error => {
      console.log(error.toJSON());
    });
  };

  const isLoggedIn = () => {
    return Boolean(cookies.access_token && cookies.refresh_token);
  };
  
  const values = {
    isLoggedIn,
    login,
    logout
  };

  return <AuthContext.Provider value={values} {...props} />;
}

export { AuthContext, AuthProvider };