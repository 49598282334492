import React, { useState, useEffect } from 'react';
import News from './news/Index';
import Tabs from './Tabs';
import { fetchData } from '../../api/Constants'

const newsAmount = 7
const postsAmount = 6

function Index() {
    const [news, setNews] = useState([...Array(newsAmount).keys()]);
    const [posts, setPosts] = useState([...Array(postsAmount).keys()]);
    
    useEffect(() => { 
        const fetchNews = async () => {
            const data = await fetchData('/api/v2/news', `limit=${newsAmount}`)
            if (data.news.length > 0) {
                setNews(data.news);
            }
        }
        fetchNews()
    }, []);

    useEffect(() => { 
        const fetchPosts = async () => {
            const data = await fetchData('/api/v2/posts', `limit=${postsAmount}`)
            setPosts(data.posts);
        }
        fetchPosts();
    }, []);

    return (
        <div>
            <News news={news} />
            <Tabs posts={posts} />
        </div>
  );
}

export default Index;