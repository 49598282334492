import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { ListItemButton, Typography, Breadcrumbs, Link, Skeleton } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import UserScore from './UserScore';
import { useParams } from "react-router-dom"
import { fetchData } from '../../api/Constants'

function Index() {
    const [evaluation, setEvaluation] = useState(null);
    const [groups, setGroups] = useState([]);
    const { evaluationId } = useParams();
    
    useEffect(() => { 
        const uid = localStorage.getItem('uid')
        const fetchGroups = async () => {
            const data = await fetchData(`/api/v2/users/${uid}/evaluations/${evaluationId}/groups`)
            setEvaluation(data.evaluation);
            setGroups(data.evaluation.groups);
        }
        fetchGroups()
    }, [evaluationId]);

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb"  sx={{ mb: 2}}>
                <Link underline="hover" color="inherit" href="/" sx={{ display: 'flex', alignItems: 'center' }}>
                    <HomeIcon sx={{ mr: 0.5 }} />
                    Página inicial
                </Link>
                <Link underline="hover" color="inherit" href="/evaluations" sx={{ display: 'flex', alignItems: 'center' }}>
                    Avaliação 360
                </Link>
                <Typography color="text.primary">Grupos</Typography>
            </Breadcrumbs>
                    
            <Card sx={{ 
                minHeight: 400, 
                marginBottom: 40,
                backgroundImage: `url(${"/logo.png"})`,
                backgroundSize: '20%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center' }}>
                <CardContent sx={{ bgcolor: 'background.paper', opacity: 0.92,  minHeight: 400 }}>
                    <UserScore  />

                    <Typography variant="h6" component="h2">
                        {evaluation ? evaluation.name : <Skeleton variant="text" />}
                    </Typography>
                    <Typography variant="subtitle1" component="h2">
                        {evaluation ? evaluation.description : <Skeleton variant="text" />}
                    </Typography>                    
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {groups.map((group, index) => {
                            return(
                                <div key={index}>
                                    <ListItemButton component="a" href={`/evaluations/${evaluation.id}/groups/${group.id}/users`} dense={true}>
                                        <ListItem alignItems="flex-start" selected={true} divider={true}>
                                            <ListItemAvatar>
                                                <Avatar alt={group.name} src="#" sx={{ bgcolor: '#faaf00'}}/>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={group.name}
                                                secondary={group.description}
                                            />  
                                        </ListItem>
                                    </ ListItemButton>
                                </div>
                            )       
                        })} 
                    </List>
                </CardContent>
            </Card>
        </div>
  );
}

export default Index;