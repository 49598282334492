import React, { useState, useEffect } from 'react';
import { Typography, Skeleton, Stack, ButtonBase } from '@mui/material'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Pagination from '@mui/material/Pagination';
import { fetchData } from '../../api/Constants'

function Index() {
    const [news, setNews] = useState([1,2,3,4,5,6]);
    const [page, setPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
  
    useEffect(() => {
        fetchNews()
    }, []);

    const fetchNews = async (page = 1) => {
        const data = await fetchData(`/api/v2/news`, `page=${page}`)
        setNews(data.news);
        setTotalPages(data.total_pages);
    }

    const handleChange = (event, value) => {
        setPage(value);
        fetchNews(value)
    };
    
    return (
        <div>
            <Typography component="h4" variant="h6" color="text.primary" sx={{mb: 1}}>Notícias</Typography>
            {news.map((news, index) => {
                return(
                    <ButtonBase href={`/news/${news.id}`} key={index} sx={{mb: 2}}>
                        <Card sx={{ display: 'flex' }} elevation={4}>
                            <CardMedia
                                component="img"
                                sx={{ width: 140}}
                                image={news.cover ? news.cover : '/loading.gif'}
                                alt={news.title}
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    {news.department ? 
                                        <Typography variant="overline" color="text.secondary" component="div">
                                            <strong>{news.department} - {news.datetime}</strong>
                                        </Typography> : <Skeleton variant="text" sx={{ fontSize: '1rem' }}/>}
                                    {news.title ? 
                                        <Typography component="h4" variant="inherit">
                                            {news.title}
                                            </Typography> : <Skeleton variant="rectangular" width={210} height={60} />}
                                </CardContent>
                            </Box>
                        </Card>
                    </ButtonBase>
                )       
            })} 
            
            {/* Paginate */}
            {news[0].title ? <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
                <Pagination count={totalPages} page={page} onChange={handleChange} color="primary" />
            </Stack> : null}                
        </div>
    );
}

export default Index;

