import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Card, CardContent, Stack} from '@mui/material'

import { HUB_HOST, HUB_API_KEY } from '../../store/constant';
import Filtered from './Filtered';


export default function Filter() {
    const [entriesMap, setEntriesMap] = useState({});
    const [yearValue, setYearValue] = useState();
    const [yearOptions, setYearOptions] = useState([]);
    const [monthValue, setMonthValue] = useState("");
    const [monthOptions, setMonthOptions] = useState([]);
    const [boletimValue, setBoletimValue] = useState("");
    const [boletimOptions, setBoletimOptions] = useState([]);
    const [mapIds, setMapIds] = useState({});
    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        _fetchData()
    }, []);

    const _fetchData = async () => {
        try {
            const response = await fetch(`${HUB_HOST}/api/v1/bi/reports/filters`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${HUB_API_KEY}`}
            })
            const data = await response.json()
            if (data) {
                const entries = Object.entries(data);
                let map = new Map()
                entries.map((entry) => {
                    map.set(entry[0], entry[1])
                })
                setEntriesMap(map)
                const yearOptions = Array.from(entries, (xs) => xs[0]);
                setYearOptions(yearOptions.reverse());
            } 
        } catch (e) {
            return;
        }
    }

    const _fetchDataByDate = async (dateSelected) => {
        try {
            const response = await fetch(`${HUB_HOST}/api/v1/bi/reports/${dateSelected}`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${HUB_API_KEY}`}
            })
            const entries = await response.json()
            if (entries) {
                let map = new Map()
                entries.map((xs) => {
                    map.set(_formatBoletimOptionText(xs["sequence_number"], xs["publication_date"]["unabbreviated"]), xs["id"])
                })
                setMapIds(map)
                setBoletimOptions(
                    Array.from(entries, (xs) => _formatBoletimOptionText(xs["sequence_number"], xs["publication_date"]["unabbreviated"]))
                );
            }
        } catch (e) {
            return;
        }
    }

    const _formatMonthOptionText = (text) => {
        return `Boletins de ${text}`;
    }

    const _formatBoletimOptionText = (number, FullDate) => {
        return `Boletim nº ${number} de ${FullDate}`;
    }

    const _clearMonthsComponent = () => {
        setMonthOptions([]);
        setMonthValue("");
    }

    const _clearBoletinsComponent = () => {
        setBoletimOptions([]);
        setBoletimValue("");
        setSelectedId(null);
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <Stack direction="row" spacing={2} sx={{mb: 4}}>
                    <Autocomplete
                        options={yearOptions}
                        renderInput={(params) => <TextField {...params} label="Selecionar Ano" />}
                        onChange={(_, newValue) => {
                            _clearMonthsComponent();
                            _clearBoletinsComponent();
                            setYearValue(newValue);
                            if (newValue) {
                                setMonthOptions(
                                    Array.from(entriesMap.get(newValue), (item) => _formatMonthOptionText(item["dateToShow"]))
                                );
                            }
                        }}
                        fullWidth
                        freeSolo
                    />

                    {   
                        monthOptions.length > 0 ? 
                            <Autocomplete
                                options={monthOptions}
                                value={monthValue}
                                renderInput={(params) => <TextField {...params} label="Selecionar Mês" />}
                                onChange={(_, newValue) => {
                                    _clearBoletinsComponent();
                                    if (newValue) {
                                        const entries = entriesMap.get(yearValue);
                                        entries.map((entry) => {
                                            if (_formatMonthOptionText(entry["dateToShow"]) === newValue) {
                                                _fetchDataByDate(entry["date"]);
                                            }
                                        })
                                        setMonthValue(newValue)
                                    }
                                }}
                                fullWidth
                                freeSolo 
                            /> : null
                    }
                    
                    {
                        monthValue !== "" ?
                            <Autocomplete
                                options={boletimOptions}
                                value={boletimValue}
                                renderInput={(params) => <TextField {...params} label="Selecionar Boletim" />}
                                onChange={(_, newValue) => {
                                    setBoletimValue(newValue);
                                    setSelectedId(mapIds.get(newValue))
                                }}
                                fullWidth
                                freeSolo
                            /> : null
                    }
                    
                </Stack>

                { selectedId ? <Filtered selectedId={selectedId} /> : null}
            </CardContent>
        </Card>
    );
}
