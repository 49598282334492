import { useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext';
import { Navigate } from "react-router-dom";

function Logout() {
  const { logout } = useContext(AuthContext);

  useEffect(() => {  
    logout();
  }, []);

  return <Navigate to="/login" />;
}

export default Logout;
