import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {
  Card, CardActions, CardContent, Skeleton
} from '@mui/material';
import { Button } from '@mui/material';
import { fetchData } from '../../api/Constants'

const LastPosts = () => {
  const [posts, setPosts] = useState(null);

  useEffect(() => { 
    const fetchPosts = async () => {
      const data = await fetchData('/api/v2/posts', 'limit=6');
      setPosts(data.posts);
    }
    fetchPosts()
  }, []);
  
  return (
    <Card sx={{ minWidth: 275,
      backgroundImage: `url(${"./logo.png"})`,
      backgroundSize: '55%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center' }}>
      <CardContent sx={{ bgcolor: 'background.paper', opacity: 0.9 }}>
        <Typography gutterBottom variant="h5" component="span">
          Últimas publicações
        </Typography>
        <List sx={{ width: '100%' }}>
          {posts ? posts.map((post, index)=>{
            return (
              <ListItem alignItems="flex-start" key={index} disablePadding>
                <ListItemButton component="a" href={post.attachment} dense={true}>
                  <ListItemText
                    primary={<b>{post.title}</b>}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                        </Typography>
                        {post.department} - {post.datetime}
                      </React.Fragment>
                    }
                  />
                </ListItemButton>
              </ListItem>)
          }) : <Skeleton variant="rectangular" height={50} />}
        </List>
      </CardContent>
      <CardActions sx={{ float: 'right'}}>
          <Button size="small" color="primary" href="/posts">Ver todas</Button>
      </CardActions>
    </Card>
  );
}

export default LastPosts;